import request from '@/services/request'
export function index(params) {
  return request({
    url: '/faqs',
    method: 'get',
    params,
  })
}
export function update(id, data) {
  return request({
    url: `/faqs/${id}`,
    method: 'put',
    data,
  })
}
export function store(data) {
  return request({
    url: '/faqs',
    method: 'post',
    data,
  })
}
export function destroy(id) {
  return request({
    url: `/faqs/${id}`,
    method: 'delete',
  })
}
