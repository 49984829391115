<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-medium">
          <span>Frequently Asked Questions</span>
          <v-btn class="ml-5" color="primary" fab small outlined @click="show()" v-if="role === 'admin'">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </h1>
      </v-col>
      <v-col cols="12" md="8" lg="6" style="height: calc(100vh - 240px); overflow: auto">
        <v-card outlined class="mb-5" v-for="question in questions" :key="question.id">
          <v-card-title>{{ question.content }}</v-card-title>
          <v-card-text v-html="question.answer"></v-card-text>
          <v-card-actions v-if="role === 'admin'">
            <v-btn color="primary" outlined @click="show(question)" small> Edit </v-btn>
            <v-btn color="primary" outlined @click="destroy(question)" small> Delete </v-btn>
            <v-btn color="primary" outlined @click="publish(question)" small>
              {{ question.isPublished ? 'Unpublished' : 'Published' }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <h3 v-if="questions.length === 0">No question found</h3>
      </v-col>
      <v-col cols="12" md="4" lg="6" class="d-none d-md-flex justify-center">
        <v-img
          contain
          max-width="170"
          src="@/assets/images/3d-characters/pose-m-1.png"
          class="security-character"
        ></v-img>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="showDialog" max-width="650">
      <v-card>
        <v-card-title>{{ editing ? 'Update' : 'New' }} Question</v-card-title>
        <v-card-text>
          <label class="mb-2 d-block">Question</label>
          <v-textarea placeholder="Enter a question" dense outlined v-model="form.content" rows="2"></v-textarea>
          <label class="mb-2 d-block">Answer</label>
          <vue-editor label="adawdw" v-model="form.answer" :editorToolbar="customToolbar"></vue-editor>
          <v-checkbox dense label="Is published?" v-model="form.isPublished"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn v-if="editing" color="primary" @click="update" text>Update</v-btn>
          <v-btn v-else color="primary" @click="create" text>Create</v-btn>
          <v-btn color="primary" text @click="showDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { VueEditor } from 'vue2-editor'
import { index, update, store, destroy } from '@/api/faq'
export default {
  components: { VueEditor },
  data() {
    return {
      icons: { mdiPlus },
      showDialog: false,
      editing: false,
      form: {
        content: '',
        answer: '',
        isPublished: true,
      },
      questions: [],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['clean'],
      ],
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user?.role?.code
    },
  },
  methods: {
    reset() {
      this.form = {
        id: undefined,
        content: '',
        answer: '',
        isPublished: true,
      }
    },
    show(question) {
      if (question) {
        this.editing = true
        this.form = {
          id: question.id,
          content: question.content,
          answer: question.answer,
          isPublished: question.isPublished,
        }
      } else {
        this.editing = false
        this.reset()
      }
      this.showDialog = true
    },

    async getQuestions() {
      this.$loader(true)
      const { data } = await index()
      this.questions = data
      this.$loader(false)
    },

    async create() {
      if (!this.form.content || !this.form.answer) {
        this.$message('Please fill all fields', 'error')
        return
      }
      try {
        this.$loader(true)
        await store(this.form)
        await this.getQuestions()
        this.showDialog = false
        this.$message('Question created successfully', 'success')
      } catch (error) {
        this.$loader(false)
      }
    },

    async update() {
      if (!this.form.content || !this.form.answer) {
        this.$message('Please fill all fields', 'error')
        return
      }
      try {
        this.$loader(true)
        await update(this.form.id, this.form)
        await this.getQuestions()
        this.showDialog = false
        this.$message('Question updated successfully', 'success')
      } finally {
        this.$loader(false)
      }
    },

    async destroy(question) {
      try {
        await this.$confirm('Are you sure when deleting this question?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
        this.$loader(true)
        await destroy(question.id)
        await this.getQuestions()
        this.$message('Question deleted successfully', 'success')
      } finally {
        this.$loader(false)
      }
    },

    async publish(question) {
      try {
        this.$loader(true)
        await update(question.id, { isPublished: !question.isPublished })
        await this.getQuestions()
        this.$message('Question updated successfully', 'success')
      } finally {
        this.$loader(false)
      }
    },
  },

  created() {
    this.getQuestions()
  },
}
</script>
